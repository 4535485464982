<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Card</h1>
              <a href="" class="pl-10">
                <div class="pagehead-link circle red-100">
                  <i class="icon font-red fa-solid fa-link"></i>
                </div>
              </a>
            </div>
            <p>
              A card represents a single piece of content or a concept, it
              should not be used to represent more than one idea.
            </p>
          </div>
        </section>
        <div class="divider max-w1200"></div>
        <div class="flex-sidebar flex-wrap">
          <div class="container max-w1000">
            <section id="general-guidelines">
              <div class="content-band">
                <h2>General Guidelines</h2>
                <ul class="bullet-red">
                  <li>
                    A card can be rendered on a page/canvas or within a pane.
                  </li>
                  <li>
                    A card should never be nested inside of another card.
                  </li>
                  <li>
                    It is preferred that a card represent a single action making
                    the entire card clickable.
                  </li>
                  <li>
                    When more than one action is present on the card, the
                    additional actions should be a convenience to the user.
                    (Quick add to calendar from an event card, add to cart for a
                    product card).
                  </li>
                </ul>
              </div>
            </section>

            <div class="divider"></div>
            <section id="import-component">
              <div class="content-band">
                <h2>Import the Component</h2>
                <ImportLinkComponent id="card-css" />
              </div>
            </section>
            <div class="divider"></div>

            <section id="card-with-photo">
              <div class="content-band">

                <div class="container mb-40">
                  <h3>Cards</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. eiusmod tempor incid idunt ut. dolore
                    magna aliqua. Sed do eiusm od tempor incididunt ut. Quod omnis dicta architecto ab sed ex fuga vel suscipit a facilis!
                  </p>
                </div>

                <div>Card with image</div>
                <CodeView component="card" type="card-default">
                  <div class="container mt-10 flex align-center justify-center gray-250 p-30 max-w800">
                    <a href="#" target="_blank" class="card white external hover-shadow">
                      <div class="card__img">
                        <img class="img-cover" src="../../../assets/images/photo_2.jpg" alt=""/>
                      </div>
                      <div class="card__content">
                        <header>
                          <div class="card__title semibold">
                            Lorem ipsum dolor sit amet cons ectetur adipiscing
                            elit sed
                          </div>
                        </header>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incid idunt ut. dolore
                          magna aliqua. Sed do eiusm od tempor incididunt ut.
                        </p>
                      </div>
                    </a>
                  </div>
                </CodeView>

                <div>Card with icon</div>
                <CodeView component="card" type="card-with-icon">
                  <div
                    class="container mt-10 flex align-center justify-center gray-250 p-30 max-w800"
                  >
                    <a href="#" target="_blank" class="card white">
                      <div class="card__img external icon">
                        <div class="circle p-20 red-700">
                          <img
                            src="../../../assets/images/card-exp-icon.png"
                            alt="Contrast Chekcer"
                          />
                        </div>
                      </div>
                      <div class="card__content">
                        <header>
                          <div class="card__title semibold">
                            Lorem ipsum dolor sit amet cons ectetur adipiscing
                            elit sed
                          </div>
                        </header>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incid idunt ut. dolore
                          magna aliqua. Sed do eiusm od tempor incididunt ut.
                        </p>
                      </div>
                    </a>
                  </div>
                </CodeView>


                <div>Cards with button</div>
                <CodeView component="card" type="card-with-button">
                  <div class="container flex-grid gap-20  justify-center gray-250 p-30 max-w800">

                    <div class="card rounded-8">
                        <div class="card__img">
                          <img class="img-top" src="../../../assets/images/service-offings.jpg" alt="Illustration">
                        </div>
                        <div class="card__content text-center">
                            <header>
                              <div class="card__title semibold">Using Ohio Applications</div>
                            </header>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit dignissimos animi vel. 
                            </p>
                            <div class="card__btn-container">
                              <button id="" class="rounded-full hover-red hover-font-white red-100">
                                  <div class="btn-txt font-red">View Calendar</div>
                                  <div class="icon icon-red">
                                      <i class="fas fa-calendar-alt"></i>
                                  </div>
                              </button> 
                          </div>
                        </div>
                      </div>

                    <div class="card rounded-8 gray-400 hover-white">
                        <div class="card__img">
                          <img class="img-top" src="../../../assets/images/id-proofing.jpg" alt="">
                        </div>
                        <div class="card__content">
                            <header>
                              <div class="card__title semibold">User-centered Design</div>
                            </header>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                              Porro tis conse simos animi vel dolores quis quam officia. 
                            </p>
                            
                            <div class="card__btn-container justify-start gap-20">
                              <button class="rounded-full secondary border-gray-500 hover-gray-500">
                                  <div class="btn-txt">Open Application</div>
                              </button> 
                              <button class="rounded-full hover-black gray-700 hover-black">
                                  <div class="btn-txt font-white">Lorem</div>
                              </button>
                          </div>
                        </div>
                    </div>


                  </div>
                </CodeView>
              </div>
            </section>

            <div class="divider"></div>

            <section id="large-card-with-photo">
              <div class="content-band">

                <div class="container mb-40">
                  <h3>Large Cards</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. eiusmod tempor incid idunt ut. dolore
                    magna aliqua. Sed do eiusm od tempor incididunt ut. Quod omnis dicta architecto ab sed ex fuga vel suscipit a facilis!
                  </p>
                </div>

                <div>Large card with image</div>
                <CodeView component="card" type="card-large">
                  <div class="container mt-10 flex align-center justify-center gray-250 p-30 max-w800">
                    <div class="card height-full width-full white max-w600 h500" role="button" tabindex="0">
                      <div class="card__img height-full hover-dark play-btn-icon pointer">
                        <img class="" src="../../../assets/images/photo_2.jpg" alt="Placeholder image description"/>
                      </div>

                      <div class="card__content">
                        <header>
                          <div class="card__title semibold">
                            Lorem ipsum dolor set
                          </div>
                        </header>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                </CodeView>

                <div>Large 2 Col with Photo &amp; button</div>
                <CodeView component="card" type="card-large-two-columns">
                  <div class="container mt-10 flex align-center justify-center gray-250 p-30 max-w800">
                    <div class="container flex even-cols height-full">
                      <div class="container red-600 line-height-none">
                        <img class="img-cover" src="../../../assets/images/photo-5.jpg" alt="" />
                      </div>
                      <div class="container red-700 flex justify-center align-center">
                        <div class="container font-white p-60">
                          <header>
                            <h3>Lorem ipsum dolor sit amet consec quam?</h3>
                          </header>
                          <p>
                            Lorem ipsum dolor sit amet consec tetur adipisicing
                            elit.
                          </p>
                          <button class="secondary border-white mt-30 rounded-full hover-white-red">
                            <div class="btn-txt font-white">
                              Open Application
                            </div>
                            <div class="icon icon-white">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 14 16"
                                width="16"
                              >
                                <path
                                  class="icon-svg"
                                  d="M14,3.5V5H0V3.5A1.5,1.5,0,0,1,1.5,2H3V.5A.51.51,0,0,1,3.5,0h1A.51.51,0,0,1,5,.5V2H9V.5A.51.51,0,0,1,9.5,0h1a.51.51,0,0,1,.5.5V2h1.5A1.5,1.5,0,0,1,14,3.5ZM0,6H14v8.5A1.5,1.5,0,0,1,12.5,16H1.5A1.5,1.5,0,0,1,0,14.5ZM2,9.62a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,9.62V8.38A.38.38,0,0,0,3.62,8H2.38A.38.38,0,0,0,2,8.38Zm0,4a.38.38,0,0,0,.38.38H3.62A.38.38,0,0,0,4,13.62V12.38A.38.38,0,0,0,3.62,12H2.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,9.62V8.38A.38.38,0,0,0,7.62,8H6.38A.38.38,0,0,0,6,8.38Zm0,4a.38.38,0,0,0,.38.38H7.62A.38.38,0,0,0,8,13.62V12.38A.38.38,0,0,0,7.62,12H6.38a.38.38,0,0,0-.38.38Zm4-4a.38.38,0,0,0,.38.38h1.24A.38.38,0,0,0,12,9.62V8.38A.38.38,0,0,0,11.62,8H10.38a.38.38,0,0,0-.38.38Zm0,4a.38.38,0,0,0,.38.38h1.24a.38.38,0,0,0,.38-.38V12.38a.38.38,0,0,0-.38-.38H10.38a.38.38,0,0,0-.38.38Z"
                                ></path>
                              </svg>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CodeView>
              </div>
            </section>
          </div>
          <StickyTOC />
        </div>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import CodeView from "../../CodeView.vue";
import StickyTOC from "../../StickyTOC";
import ImportLinkComponent from "../../../components/ImportLinkComponent";
import TempLeftNav from "../../TempLeftNav";
import SubFooter from "../../../components/SubFooter";
export default {
  name: "Card",
  components: {
    TempLeftNav,
    SubFooter,
    CodeView,
    ImportLinkComponent,
    StickyTOC,
  },
};
</script>
